<template>
  <div>
    <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
  <template #button-content>
      <div v-if="myData.fullName || myData.username || myData.first_name || myData.last_name" class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ myData.fullName || myData.username || `${myData.first_name} ${myData.last_name}` }}
        </p>
        <span class="user-status">{{ $options.filters.title(myData.role) }}</span>
      </div>
      <div v-else class="d-sm-flex d-none user-nav skelenton-profile">
        <b-skeleton animation="wave" width="100%" height="20px"></b-skeleton>
        <b-skeleton animation="wave" width="50%" height="10px"></b-skeleton>
      </div>
        <feather-icon
          v-if="!myData.fullName"
          icon="MoreVerticalIcon"
          size="22"
          class="d-md-none"
        />
    </template>

    <b-dropdown-item
      :to="{ name: 'profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{$t('Perfil')}}</span>
    </b-dropdown-item>
    <div v-if="['admin'].includes(myData.role)">
        <b-dropdown-divider />
        <b-dropdown-item 
          @click="openModalCustomLogin"   
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="KeyIcon"
            class="mr-50"
          />
          <span>{{$t('Ver como')}}</span>
        </b-dropdown-item>
    </div>
    <b-dropdown-divider />
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{$t('Cerrar sesión')}}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
    <modal-custom-login ref="ModalCustomLogin"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider
} from 'bootstrap-vue' // , BAvatar
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import ModalCustomLogin from './ModalCustomLogin.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    ModalCustomLogin //,
    // BAvatar
  },
  data() {
    return {
      myData: this.$session.get('cas_user') || {},
      avatarText
    }
  },
  watch: {
    userData: {
      handler () {
        this.myData = this.userData
      },
      deep: true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({
      userData: 'getUserData'
    })
  },
  methods: {
    logout() {
      this.$root.$data.auth.signOut()
        .then(resp => {
          Cookies.remove('session_id')
        })
      this.$store.dispatch('fetchLogout', this)
    },
    openModalCustomLogin () {
      this.$bvModal.show('ModalCustomLogin')
    }
  }
}
</script>

<style lang="scss">
.skelenton-profile {
  width: 80px !important;
  div {
    margin-bottom: 5px;
  }
}
</style>