export default [
  {
    title: 'menu-deliveries',
    icon: 'SendIcon',
    resource: 'menu',
    action: 'deliveries',
    children: [
      {
        title: 'menu-deliveries-create',
        route: 'deliveries-create',
        resource: 'deliveries',
        action: 'create'
      },
      {
        title: 'menu-deliveries-list',
        route: 'deliveries-list',
        resource: 'deliveries',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-returns',
    icon: 'CornerUpLeftIcon',
    resource: 'menu',
    action: 'returns',
    children: [
      {
        title: 'menu-returns-create',
        route: 'returns',
        resource: 'returns',
        action: 'create'
      },
      {
        title: 'menu-returns-list',
        route: 'returns-list',
        resource: 'returns',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-deliveries-flash',
    icon: 'ZapIcon',
    resource: 'menu',
    action: 'envia-flash',
    children: [
      {
        title: 'menu-deliveries-flash-create',
        route: 'envia-flash',
        resource: 'envia-flash',
        action: 'create'
      },
      {
        title: 'menu-deliveries-flash-config',
        route: 'envia-flash-config',
        resource: 'envia-flash',
        action: 'config'
      }
    ]
  },
  {
    title: 'menu-tickets',
    icon: 'FileTextIcon',
    resource: 'menu',
    action: 'tickets',
    children: [
      {
        title: 'menu-tickets-pending',
        route: 'tickets-pending',
        resource: 'tickets',
        action: 'pending'
      },
      {
        title: 'menu-tickets-panel',
        route: 'tickets-madagascar',
        resource: 'tickets',
        action: 'detail'
      }
    ]
  },
  {
    title: 'menu-rules-tickets',
    icon: 'ClipboardIcon',
    resource: 'menu',
    action: 'rule-tickets',
    children: [
      {
        title: 'menu-rules-tickets-list',
        route: 'tm-rule-list',
        resource: 'rule-tickets',
        action: 'list'
      },
      {
        title: 'menu-rules-tickets-create',
        route: 'tm-rule-create',
        resource: 'rule-tickets',
        action: 'create'
      },
      {
        title: 'menu-rules-tickets-group-list',
        route: 'tm-group-list',
        resource: 'rule-tickets',
        action: 'groups'
      },
      {
        title: 'menu-rules-tickets-group-create',
        route: 'tm-group-create',
        resource: 'rule-tickets',
        action: 'create-group'
      }
    ]
  },
  {
    title: 'menu-cash',
    icon: 'FileIcon',
    route: {
      path: '/recaudos/:id'
    },
    resource: 'recaudos',
    action: 'list'
  }  
]
