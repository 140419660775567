<template>
	<div>
		<b-modal id="modalSearchDeliveries" scrollable size="xl" modal-class="custom-dialog"
    hide-footer no-close-on-backdrop :title="$t('Envíos')" @show="onShow">
    <div class="row">
      <div class="col-12 d-flex justify-content-end mb-1">
        <b-button class="push-left" variant="success" @click="downloadDeliveries" :disabled="loading">
          {{$t('Exportar envíos')}} 
          <feather-icon v-if="!loading" icon="DownloadIcon"/>
          <b-spinner v-else small></b-spinner>
        </b-button>
      </div>
      <div>
        <table-render id="table-search-deliveries" :schema="schema" :rows.sync="rows" class="mx-1" :key="keyTableRender">
        <template #current_status="scope">
          <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status.id]}`"></span> {{scope.rowdata.status.name}}
        </template>
        <template #imported_id="scope">
          <a :href="generateUrl(scope.rowdata)" class="p-0" @click.prevent="handleLinkClick(scope.rowdata)">
            {{ scope.rowdata.imported_id }}
          </a>
        </template>
          <template #place_name="scope">
            <a v-b-tooltip.click.html="scope.rowdata.place_information" class="lnk underline-text">{{scope.rowdata.shipping_address.place}}</a>
          </template>
          <template #pickup_date="scope">
            {{scope.rowdata.pickup_date || "sin info."}}
          </template>
          <template #created_at="scope">
            <a v-b-tooltip.click.html="scope.rowdata.created_at_hour" class="lnk underline-text">{{scope.rowdata.created_at}}</a>
          </template>
        </table-render>
      </div>
    </div>    
		</b-modal>
	</div>
</template>

<script>

export default {
  props: ['data'],
  data () {
    return {
      schema: [],
      rows: [],
      keyTableRender: 0,
      loading: false
    }
  },
  watch: {
    data () {
      this.rows = this.data
      this.keyTableRender++
    }
  },
  methods: {
    handleLinkClick(rowdata) {
      // Cierra el modal
      this.$bvModal.hide('modalSearchDeliveries')
      // Redirige a la ruta correspondiente
      const nameRoute = rowdata.type === 'Envío' ? 'deliveries-details' : 'return-details'
      const identifier = rowdata.id
      if (this.$route.name === nameRoute && Number(this.$route.params.id) === identifier) {
        this.$bvToast.toast(`Ya se encuentra viendo el detalle ${nameRoute === 'return-details' ? 'de la devolución: ' : 'del envío: '} ${rowdata.id}`, {
          title: this.$t('Aviso'),
          variant: 'warning',
          solid: true
        })
      } else {
        this.$router.push({ name: nameRoute, params: { id: identifier } })
      }
    },
    generateUrl(rowdata) {
      return this.$router.resolve({ name: rowdata.type === 'Envío' ? 'deliveries-details' : 'return-details', params: { id: rowdata.id } }).href
    },
    setInitialData () {
      this.schema = [
        {label: this.$t('Estado'), key: 'current_status', useSlot: true},
        {label: this.$t('Tipo'), key: 'type'},
        {label: this.$t('N. de envío'), key: 'imported_id', useSlot: true, wrap: true},
        {label: this.$t('Orden de flete'), key: 'tracking_number', wrap: true},
        {label: this.$t('Cliente'), key: 'customer_name'},
        {label: this.$t('Destino'), key: 'destination'},
        {label: this.$t('Comuna'), key: 'place_name', useSlot: true},
        {label: this.$t('Courier'), key: 'carrier'},
        {label: this.$t('Fecha creación'), key: 'created_at', useSlot: true, wrap: true},
        {label: this.$t('Fecha compromiso'), key: 'deadline_at', wrap: true}
      ]
      if (['marketplace', 'superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        this.schema.unshift({label: this.$t('Empresa'), key: 'company_name'})
      }
    },
    onShow() {
      this.setInitialData()
    },
    downloadDeliveries() {
      this.loading = true
      const ids = this.data.map(delivery => delivery.id)
      const queryParams = {
        deliveries: ids
      }
      this.$store.dispatch('fetchService', { 
        name: 'filterExportDeliveriesByTag', queryParams, hideAlert: true,
        onSuccess: this.showAlert, 
        onError: () => this.showAlert(false)
      })
    },
    showAlert(isSuccess = true) {
      this.$bvToast.toast(isSuccess ? 'Reporte será enviado a su correo' : 'Ocurrió un error en la exportación', {
        title: isSuccess ? '¡Felicitaciones!' : '¡Oops!',
        variant: isSuccess ? 'success' : 'danger',
        solid: true
      })
      this.loading = false
    }
  }
}
</script>
<style>
a.underline-text{
  text-decoration: underline !important;
}
</style>
