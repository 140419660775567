<template>
  <div>
    <b-button
      size="sm"
      variant="link"
      class="btn-tour-skip px-0"
      :disabled="!readyNotifications"
      @click="showNotification()"
    >
      <feather-icon
        :badge="newNotifications || ''"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </b-button>
    <div :class="showListNoti ? 'div-notification' : ''">
      <viewer-madagascar 
        @closeNotification="showNotification"
        :showListNoti="showListNoti"
        @notificationsLoadedFromFirestore="notificationsLoadedFromFirestore"/>
    </div>
  </div>
  <!-- <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="newNotifications || ''"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
      <div class="dropdown-header d-flex">
        <viewer/>
      </div>
  </b-nav-item-dropdown> -->    
</template>

<script>
import { mapState } from 'vuex'
import ModalShowNotification from './ModalShowNotification.vue'
import { BBadge, BMedia, BLink, BAvatar, BButton, BNavItemDropdown } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ViewerMadagascar from '@/views/modules/configuration/notifications/ViewerMadagascar.vue'

export default {
  components: {
    // BNavItemDropdown,
    // BBadge,
    // BMedia,
    // BLink,
    // BAvatar,
    // VuePerfectScrollbar,
    // BButton,
    // ModalShowNotification,

    ViewerMadagascar
  },
  directives: {
    Ripple
  },
  data () {
    return {
      systemNotifications: [],
      loading: false,
      newNotifications: 0,
      indexNotification: -1,
      showListNoti: false,
      readyNotifications: false
    }
  },
  computed: {
    ...mapState(['notifications'])
  },
  watch: {
    indexNotification (curr, prev) {
      if (curr !== -1) this.$store.dispatch('markAsRead', this.notifications.list[curr].id)
    }
  },
  mounted () {
    // this.getNotifications()
  },
  methods : {
    openNotification (_id) {
      this.indexNotification = this.notifications.list.findIndex(({id}) => id === _id)
      this.$bvModal.show('modalShowNotification')
    },
    showNotification() {
      this.showListNoti = !this.showListNoti
    },
    notificationsLoadedFromFirestore (value) {
      this.readyNotifications = value
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }

    return {
      perfectScrollbarSettings
    }
  }
}
</script>

<style>
.div-notification{
  position: absolute;
  top: -1rem;
  background: #161d311c;
  width: 150%;
  height: 100vh;
  left: -2rem;
  padding: 0;
  z-index: 9999 !important;
}
</style>
