<template>
    <div class="rule-dialog">
      <b-modal 
        id="modalViewer" 
        modal-class="no-header custom-dialog dialog-1200"
        ok-only 
        no-close-on-esc 
        no-close-on-backdrop 
        centered 
        no-footer>
        <div>
          <div class="flex-flags" v-if="!!isAdmin">
              <span style="font-size: 1rem; font-weight: bold;" v-if="displayedNotification.country.code === 'all'">{{ displayedNotification.country.text }}</span>
              <img class="styles-flags" style="margin-right: 0;" v-if="displayedNotification.country.code !== 'all'" :src="require(`@/assets/images/flags/${displayedNotification.country.code.toLowerCase()}.png`)" :alt="displayedNotification.country.text">
          </div>
          <div class="scrollable-notification">
            <div v-html="displayedNotification.content"></div>
          </div>
        </div>
        <template #modal-footer="">
          <div class="actions-btn">
            <b-button @click="finish" v-if="showClose" variant="danger" class="push-right">{{$t('Cerrar')}}</b-button>
            <b-button @click="next" v-if="showNext" variant="success" class="push-right">{{$t('Siguiente')}} <feather-icon icon="ArrowRightIcon"/></b-button>
            <b-button @click="prev" v-if="showPrev" variant="success" class="mr-10 prev"><feather-icon icon="ArrowLeftIcon"/> {{$t('Anterior')}}</b-button>   
            <b-button @click="finish" v-if="showCloseAdmin && !!isAdmin" class="center-button-all-close-notifications" variant="danger">{{$t('Cerrar todos')}}</b-button>
          </div>
        </template>
      </b-modal>
    </div>
</template>
<script>
export default {
  name: 'modal-view',
  props: ['displayedNotification', 'isAdmin', 'showClose', 'showNext', 'showPrev', 'showCloseAdmin'],
  data () {
    return {
      
    }
  },
  methods: {
    next() {
      this.$emit('next')
    },
    prev() {
      this.$emit('prev')
    },
    finish() {
      this.$emit('finish')
    }
  } 
}
</script>
<style lang="scss">
.actions-btn {
  position: relative;
  bottom:0;
  left:0;
  width: 100%;
}
.center-button-all-close-notifications {
  position: absolute;
  right: 40%;
}
.scrollable-notification {
  height: 635px;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 5%);
  overflow: scroll;
  img {
    max-width: calc(100vw - 5%);
  }
}
.scrollable-notification::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scrollable-notification::-webkit-scrollbar:vertical {
  width: 11px;
}
.scrollable-notification::-webkit-scrollbar:horizontal {
  height: 11px;
}
.scrollable-notification::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.scrollable-notification::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
</style>
