<template>
  <b-modal 
    id="ModalCustomLogin" 
    title="Inicio de sesión personalizado" 
    modal-class="dialog-600" 
    ok-only 
    no-close-on-esc 
    no-close-on-backdrop 
    @close="close"
    centered
    hide-footer
  >
    <form-render
      @send="getUserToken"
      :form.sync="form"
      :fields="fields"
      containerButtonsClass="col-sm-12 d-flex justify-content-end"
      ref="formRenderCustomToken"
    />   
    <div class="d-block">
      <b-alert show variant="warning">
        <div class="alert-body d-flex align-items-center text-dark">
          <feather-icon icon="AlertTriangleIcon" size="16"/>&ensp;<small>Correos <b>@enviame.io</b> no son permitidos.</small>
        </div>
      </b-alert>
    </div>  
    <div class="d-flex justify-content-end mb-1">
      <b-button variant="warning" class="quoterButton" @click="validateForm" :disabled="loading">
        <span class="mb-1"><b-spinner v-if="loading" small /> Iniciar sesión</span>
      </b-button>
    </div>         
  </b-modal>
</template>
<script>
import Cookies from 'js-cookie'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
export default {
  name: 'custom-login',
  data () {
    return {
      loading: false,
      form: {},
      fields: []
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    close () {
      this.form = {}
    },
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: 'E-mail', containerClass: 'col-sm-12 container-info', validation: 'required|email' }
      ]
    },
    validateForm () {
      this.$refs.formRenderCustomToken.checkForm()
    },
    getUserToken (data) {
      this.loading = true
      const payload = {
        email: data.email
      }
      this.$store.dispatch('fetchService', {
        name: 'postCustomToken',
        queryParams: payload,
        onSuccess: (resp) => this.sucessTokenCreated(resp.data),
        onError: (err) => this.errorTokenCreated(err),
        hideAlert: true
      })
    },
    sucessTokenCreated (data) {
      this.customLogin(data.token)
    },
    errorTokenCreated (err) {
      this.loading = false
      this.$alert(this.$t('msg-problema-obtener-token', { code: err }))
    },
    customLogin (token) {
      this.$root.$data.auth
        .signInWithCustomToken(token)
        .then((data) => {
          this.clearSession()
          this.$root.$data.auth.setPersistence(this.$root.$data.fb.auth.Auth.Persistence.LOCAL)
          this.loggedUser = data.user
          return data.user.getIdToken()
        })
        .then((token) => {
          this.loading = false
          this.$bvModal.hide('ModalCustomLogin')
          this.$store.dispatch('fetchUserData', {id: this.loggedUser.uid, token, from: 'custom-token', remember: true })
        })
        .catch((error) => {
          this.loading = false
          this.$alert(this.$t('msg-problema-iniciar-sesion', { code: error }))
        })
    },
    clearSession () {
      Cookies.remove('session_id')
      localStorage.clear()
      this.$session.destroy()
      this.$ability.update(initialAbility)
      localStorage.setItem('custom-token', true)
    }
  }
}
</script>