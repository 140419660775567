<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header--> 
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto nav-header">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-only-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle nav-header">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
    <!-- :items="navMenuItems" -->
      <vertical-nav-menu-items
        :items="menuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { EventBus } from '@/@core/utils/eventBus'
import Vue from 'vue'
import adminMenu from '@/navigation/vertical/new-menu/admin'
import mkpMenu from '@/navigation/vertical/new-menu/mkp'
import companyMenu from '@/navigation/vertical/new-menu/company'
import pudoMenu from '@/navigation/vertical/new-menu/pudo'
import contactMenu from '@/navigation/vertical/new-menu/contactCenter'
import customMenu from '@/navigation/vertical/new-menu/custom'
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true
    },
    tourActive: {
      type: Boolean
    }
  },
  setup(props) {
    let role = Vue.prototype.$session.get('cas_user')?.role
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered
    } = useVerticalNavMenu(props)
    const { skin } = useAppConfig()    
    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    provide('isMouseHovered', isMouseHovered)
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const menuOption = {
      admin: adminMenu,
      superadmin: adminMenu,
      marketplace: mkpMenu,
      seller:  companyMenu,
      ecommerce: companyMenu,
      pudo: pudoMenu,
      contact: contactMenu,
      custom: customMenu
    }
    const getMenuOptions = () => {
      return menuOption[role] || []
    }
    
    const menuItems = ref(getMenuOptions())
    
    EventBus.$on('reload-menu', (newRole) => {
      role = newRole // Actualizar el rol del usuario
      menuItems.value = getMenuOptions() // Actualizar los elementos del menú
    })

    watch(
      () => props.tourActive,
      (newVal, oldVal) => {
        if (newVal) {
          toggleCollapsed() 
        } 
        else if (!newVal && oldVal !== undefined) {
          toggleCollapsed() 
          // Esto se debe a que mas abajo de 1200px se visualiza es un menu hamburguesa
          // para cerrarlo es necesario llamar esta función
          if (window.innerWidth <= 1200 && window.innerWidth >= 768) {
            props.toggleVerticalMenuActive() 
          }
        }
      }, 
      { immediate: true }
    )

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      adminMenu,
      mkpMenu,
      companyMenu,
      pudoMenu,
      contactMenu,
      customMenu,
      getMenuOptions,
      menuItems,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage
    }
  }
}
</script>

<style lang="scss">
.menu-collapsed.vertical-menu-modern .main-menu:not(.expanded) .navbar-header .navbar-brand .brand-only-logo {
  width: 35px !important;
  overflow: hidden;
}
.main-menu .navbar-header .navbar-brand .brand-only-logo img {
  width: 150px;
  padding-left: 1px;
}
.main-menu .navbar-header {
  margin-bottom: 1rem !important;
}
.navigation.navigation-main a{
  font-size: 13px;
}
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
