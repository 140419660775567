export default [
  {
    title: 'menu-metrics',
    icon: 'BarChartIcon',
    children: [
      {
        title: 'Dashboard',
        route: 'dashboard',
        resource: 'menu',
        action: 'dashboard'
      },
      {
        title: 'menu-reports',
        route: 'automatic-reports',
        resource: 'automatic-reports',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-deliveries',
    icon: 'SendIcon',
    children: [
      {
        title: 'menu-deliveries-create',
        route: {
          path: '/deliveries/create',
          hash: 'create'
        },
        resource: 'deliveries',
        action: 'create'
      },
      {
        title: 'menu-deliveries-list',
        route: 'deliveries-list',
        resource: 'deliveries',
        action: 'list'
      },
      {
        title: 'menu-deliveries-summaries',
        route: {
          path: '/deliveries/create',
          hash: 'summaries'
        },
        resource: 'deliveries',
        action: 'create'
      }
    ]
  },
  {
    title: 'menu-deliveries-flash',
    icon: 'ZapIcon',
    resource: 'menu',
    action: 'envia-flash',
    children: [
      {
        title: 'menu-deliveries-flash-create',
        route: 'envia-flash',
        resource: 'envia-flash',
        action: 'create'
      },
      {
        title: 'menu-deliveries-flash-config',
        route: 'envia-flash-config',
        resource: 'envia-flash',
        action: 'config'
      }
    ]
  },
  {
    title: 'menu-returns',
    icon: 'CornerUpLeftIcon',
    resource: 'menu',
    action: 'returns',
    children: [
      {
        title: 'menu-returns-create',
        route: 'returns',
        resource: 'returns',
        action: 'create'
      },
      {
        title: 'menu-returns-list',
        route: 'returns-list',
        resource: 'returns',
        action: 'list'
      }
    ]
  },
  {
    title: 'menu-pickups',
    icon: 'TruckIcon',
    route: 'pickups',
    resource: 'pickups',
    action: 'admin'
  },
  {
    title: 'menu-pudos',
    icon: 'MapPinIcon',
    route: 'pudos',
    resource: 'pudos',
    action: 'list',
    tag: 'beta'
  },
  {
    title: 'menu-carrier',
    icon: 'PackageIcon',
    resource: 'menu',
    action: 'carriers',
    children: [
      {
        title: 'menu-carrier-list',
        route: 'config-carriers-platforms',
        resource: 'carriers',
        action: 'mantenedor'
      },
      {
        title: 'menu-carrier-config',
        route: 'list-carriers',
        resource: 'carriers',
        action: 'config'
      }
    ]
  },
  {
    title: 'menu-config',
    icon: 'SettingsIcon',
    resource: 'menu',
    action: 'config',
    children: [
      {
        title: 'menu-config-users',
        route: 'config-users',
        resource: 'config',
        action: 'users'
      },
      {
        title: 'menu-config-tracking',
        route: 'config-tracking',
        resource: 'config',
        action: 'tracking'
      },
      {
        title: 'menu-default-answer',
        route: 'default-answer',
        resource: 'config',
        action: 'default-answer'
      },
      {
        title: 'menu-config-notifications',
        route: 'config-notifications',
        resource: 'config',
        action: 'notifications'
      }
    ]
  },
  {
    title: 'menu-marketcenter',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'menu-marketcenter-canal',
        route: 'marketcenter',
        resource: 'marketcenter',
        action: 'config'
      },
      {
        title: 'menu-marketcenter-deliveries',
        route: 'marketcenter-deliveries',
        resource: 'marketcenter',
        action: 'deliveries'
      },
      {
        title: 'menu-marketcenter-products',
        route: 'marketcenter-products',
        resource: 'marketcenter',
        action: 'products'
      },
      {
        title: 'menu-marketcenter-labels',
        route: 'marketcenter-labels',
        resource: 'marketcenter',
        action: 'labels'
      }
    ]
  },
  {
    title: 'menu-tickets',
    icon: 'FileTextIcon',
    resource: 'menu',
    action: 'tickets',
    children: [
      {
        title: 'menu-tickets-pending',
        route: 'tickets-pending',
        resource: 'tickets',
        action: 'pending'
      },
      {
        title: 'menu-tickets-assignments',
        route: 'assign-user',
        resource: 'tickets',
        action: 'assignments'
      },
      {
        title: 'menu-tickets-panel',
        route: 'tickets-madagascar',
        resource: 'tickets',
        action: 'detail'
      }
    ]
  },
  {
    title: 'menu-rules-tickets',
    icon: 'ClipboardIcon',
    resource: 'menu',
    action: 'rule-tickets',
    children: [
      {
        title: 'menu-rules-tickets-list',
        route: 'tm-rule-list',
        resource: 'rule-tickets',
        action: 'list'
      },
      {
        title: 'menu-rules-tickets-create',
        route: 'tm-rule-create',
        resource: 'rule-tickets',
        action: 'create'
      },
      {
        title: 'menu-rules-tickets-group-list',
        route: 'tm-group-list',
        resource: 'rule-tickets',
        action: 'groups'
      },
      {
        title: 'menu-rules-tickets-group-create',
        route: 'tm-group-create',
        resource: 'rule-tickets',
        action: 'create-group'
      }
    ]
  },
  {
    title: 'menu-rules',
    icon: 'SlidersIcon',
    route: 'advanced-rules-list',
    tag: 'beta',
    resource: 'advanced-rules',
    action: 'list'
  },
  {
    title: 'menu-materials',
    icon: 'ArchiveIcon',
    route: 'materials',
    resource: 'materials',
    action: 'list'
  },
  {
    title: 'menu-companies',
    icon: 'TabletIcon',
    route: 'companies',
    resource: 'companies',
    action: 'list'
  },
  {
    title: 'menu-billing',
    icon: 'FileIcon',
    route: 'billing-manage',
    resource: 'billing',
    action: 'manage'
  },
  {
    title: 'menu-pricing',
    icon: 'DollarSignIcon',
    resource: 'menu',
    action: 'pricing',
    children: [
      {
        title: 'menu-pricing-calculator',
        route: 'tariff-calculator',
        resource: 'pricing',
        action: 'calculator'
      },
      {
        title: 'menu-pricing-manage',
        route: 'manage-tariffs',
        resource: 'pricing',
        action: 'manage'
      }
    ]
  },  
  {
    title: 'menu-support',
    icon: 'HelpCircleIcon',
    route: 'support',
    resource: 'support',
    action: 'info'
  }
]
