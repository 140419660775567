<template>
	<div>
		<b-modal :id="idModal" size="sm" modal-class="custom-dialog"
    hide-footer no-close-on-backdrop :title="$t('Envíos')">
			<div class="mt-0">
				<form-render :key="keyFormRender" :fields="fields" :form.sync="form" ref="formRenderMultiSearch" containerButtonsClass="col-sm-12" /> 
        <div class="d-flex justify-content-end">
          <b-button class="m-1" variant="warning" @click="ok" :disabled="loading.filter">
            <b-spinner v-if="loading.filter" small/>
            <span class="mb-1">{{$t('Buscar')}}</span>
          </b-button>
        </div>
			</div>
		</b-modal>
	</div>
</template>

<script>

export default {
  props: ['filter', 'id'],
  data () {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      loading: { filter: false },
      idModal: 'modalMultiSearch'
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (this.id) this.idModal = this.id
      this.fields = [
        {fieldType: 'FieldTextarea', name: 'ids', label: 'Agregue o pegue los envíos que desea buscar separados por un "Enter"', containerClass: 'col-sm-12 container-info', rows: 5 }
      ]
    },
    resetForm () {
      this.loading.filter = false
      this.$bvModal.hide('modalMultiSearch')
    },
    ok() {
      this.loading.filter = true
      const filter = { number: this.form.ids.split('\n').join(',') }
      this.$emit('filter', filter)
    }
  }
}
</script>
<style>

</style>
